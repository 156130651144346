
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BackDrop',
  props: {
    isShowing: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('back-drop-click')
    },
  },

})
